import {checkResponse} from "../utils";
import {authServiceBaseUrl} from "../consts";
export function signUp(name, email, password, captchaToken) {
    return fetch(`${authServiceBaseUrl}/sign-up?platform=site`, {
            method: 'POST',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                captchaToken: captchaToken,
                username: name,
                email: email,
                password: password
            })
        })
            .then((res) => checkResponse(res))
}
